.container {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  background: linear-gradient(270deg, #ffebaf 0%, #ffdcc8 50%, #ffe1ff 100%);
  border-radius: 4px;
  padding: 20px 24px;
  cursor: pointer;
}

.heading-wrapper {
  margin-bottom: 4px;
}

.button {
  margin-top: 16px;
}

.image-container {
  height: 120px;
  width: 375px;
  background: url('https://static.cdn-cian.ru/frontend/commercial-assets/images/best_place.png');
  background-size: cover;
  align-self: flex-start;
}
